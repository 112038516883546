import { useState } from "react";

export default function Updates() {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    organization: "",
    position: "",
    website: "",
    comment: "",
  });

  const mailData = {
    list: "https://invently.us1.list-manage.com/subscribe/post",
    u: "805e834246003d35d0398947f",
    id: "3b7e815877",
    spam: "b_805e834246003d35d0398947f_3b7e815877",
  };

  return (
    <div className="d-flex flex-column text-center align-items-center">
      <h1 className="display-1 mx-5 mt-5 mb-3">Invently Automotive</h1>
      <h3 className="display-6 mx-5 mb-5">
        Connected Transportation Technology
      </h3>
      <form
        className="d-flex flex-column w-75 my-3 px-md-5"
        action={mailData.list}
        method="POST"
        noValidate
      >
        <input type="hidden" name="u" value={mailData.u} />
        <input type="hidden" name="id" value={mailData.id} />
        <div className="row mb-2 g-1">
          <div className="col">
            <div className="form-floating mb-2">
              <input
                className="form-control bg-transparent"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                value={formData.email}
                placeholder="Email address"
                type="email"
                name="EMAIL"
                id="MERGE0"
              />
              <label htmlFor="MERGE0">Email address</label>
            </div>

            <div className="form-floating mb-2">
              <input
                className="form-control bg-transparent"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                value={formData.name}
                placeholder="Name"
                type="text"
                name="MERGE5"
                id="MERGE5"
              />
              <label htmlFor="MERGE5">Name</label>
            </div>

            <div className="form-floating mb-2">
              <input
                className="form-control bg-transparent"
                onChange={(e) =>
                  setFormData({ ...formData, organization: e.target.value })
                }
                value={formData.organization}
                placeholder="Organization"
                type="text"
                name="MERGE6"
                id="MERGE6"
              />
              <label htmlFor="MERGE6">Organization</label>
            </div>

            <div className="form-floating mb-2">
              <input
                className="form-control bg-transparent"
                onChange={(e) =>
                  setFormData({ ...formData, position: e.target.value })
                }
                value={formData.position}
                placeholder="Position"
                type="text"
                name="MERGE7"
                id="MERGE7"
              />
              <label htmlFor="MERGE7">Position</label>
            </div>

            <div className="form-floating mb-2">
              <input
                className="form-control bg-transparent"
                onChange={(e) =>
                  setFormData({ ...formData, website: e.target.value })
                }
                value={formData.website}
                placeholder="Website"
                type="url"
                name="MERGE8"
                id="MERGE8"
              />
              <label htmlFor="MERGE8">Website</label>
            </div>

            <div className="form-floating mb-2">
              <input
                className="form-control bg-transparent"
                onChange={(e) =>
                  setFormData({ ...formData, comment: e.target.value })
                }
                value={formData.comment}
                placeholder="Comments"
                type="text"
                name="MERGE9"
                id="MERGE9"
              />
              <label htmlFor="MERGE9">Comment</label>
            </div>
          </div>
        </div>

        <input
          type="submit"
          value="Sign up for updates"
          className="btn btn-transparent border px-5 py-3 mb-5"
        />

        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
          aria-label="Please leave the following field empty"
        >
          <label htmlFor={mailData.spam}>Name: </label>
          <input type="text" name={mailData.spam} tabindex="-1" value="" />
        </div>
      </form>
    </div>
  );
}
