import Footer from "./components/Footer";
import Updates from "./components/Updates";
import background from "./assets/auto_background.jpg";

export default function App() {
  return (
    <div
      className="d-flex flex-column vh-100"
      style={{
        background: `url("${background}") no-repeat center center/cover`,
      }}
    >
      <Updates />
      <Footer />
    </div>
  );
}
